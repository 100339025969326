export default ({
  children,
  marginTop,
  marginBottom,
  className,
  size,
  color,
  mobileFontSize,
  subtitleContent
}) => {
  return (
    <div>
      <div className="title-container title-margin">
        <span className={`title-contents has-text-weight-light ${className}`}>{children}</span>
        {subtitleContent ? <div className="subtitle-contents">{subtitleContent}</div> : ''}
      </div>
      <style jsx="true">{`
        .subtitle-contents {
          font-size: 14pt;
          ${color ? `color: ${color};` : ''}
          font-family: SFCompactText;
          margin-top: 10px;
        }
        .title-contents {
          font-size: ${size ? size : '36px'};
          ${color ? `color: ${color};` : ''}
          font-size: 36px;
          font-family: FuturaLight;
        }

        .title-container {
          display: inline-block;
          margin-top: ${marginTop ? marginTop : '79px'};
          margin-bottom: ${marginBottom ? marginBottom : '63px'};
        }

        @media all and (max-width: 768px) {
          .title-contents {
            font-size: 30px;
            ${mobileFontSize && `font-size: ${mobileFontSize};`}
          }

          .title-container {
            margin-top: ${marginTop ? marginTop : '33px'};
            margin-bottom: ${marginBottom ? marginBottom : '33px'};
          }
        }
      `}</style>
    </div>
  );
};
