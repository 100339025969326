import React, { useReducer } from 'react';
import CustomerReducer from './Customer.reducer';

const CustomerContext = React.createContext();

const CustomerProvider = props => {
  const [state, dispatch] = useReducer(CustomerReducer, {
    customer: null,
    customerFetchStatus: null,
    customerUpdateStatus: null,
    customerCreateStatus: null,
    loggedIn: false,
    error: null
  });

  return (
    <CustomerContext.Provider
      value={{
        loggedIn: state.loggedIn,
        customerFetchStatus: state.customerFetchStatus,
        customerUpdateStatus: state.customerUpdateStatus,
        customerCreateStatus: state.customerCreateStatus,
        customer: state.customer,
        error: state.error,
        dispatch: dispatch
      }}
    >
      {props.children}
    </CustomerContext.Provider>
  );
};

const CustomerConsumer = CustomerContext.Consumer;

export { CustomerConsumer, CustomerProvider, CustomerContext };
