import CoveApi from 'utilities/coveApi2';
import * as Sentry from '@sentry/browser';

import { Mixpanel } from 'utilities/mixpanel';

export const CREATE_CUSTOMER_PENDING = 'CREATE_CUSTOMER';
export const CREATE_CUSTOMER_FAILED = 'CREATE_CUSTOMER_FAILED';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const UPDATING_CUSTOMER_PENDING = 'UPDATE_CUSTOMER';
export const UPDATING_CUSTOMER_FAILED = 'UPDATE_CUSTOMER_FAILED';
export const UPDATING_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_PENDING = 'FETCH_CUSTOMER';
export const FETCH_CUSTOMER_FAILED = 'FETCH_CUSTOMER_FAILED';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const NO_CUSTOMER_STORED = 'NO_CUSTOMER_STORED';
export const CLEAR_STATUSES = 'CLEAR_STATUSES';

const CustomerApi = CoveApi('Customer');

export const createCustomer = dispatch => async customerPayload => {
  dispatch({
    type: CREATE_CUSTOMER_PENDING
  });
  try {
    CustomerApi.accessToken = null;
    const customerResponse = await CustomerApi.create(customerPayload);

    localStorage.setItem('cove-access-token', customerResponse.token);
    localStorage.setItem('cove-customer-id', customerResponse.customer_id);

    Sentry.configureScope(scope => {
      scope.setUser({
        id: customerResponse.customer_id
      });
    });
    Mixpanel.alias(customerResponse.customer_id);
    dispatch({
      type: CREATE_CUSTOMER_SUCCESS,
      customer: {
        ...customerResponse,
        ...customerPayload // NOTE: need to inflate because customer.create doesn't return the whole customer object
      }
    });
  } catch (err) {
    dispatch({
      type: CREATE_CUSTOMER_FAILED,
      error: err
    });
  }
};

export const loadCustomerFromStorage = dispatch => async () => {
  // this is the get customer call
  const customerId = localStorage.getItem('cove-customer-id');
  if (customerId) {
    dispatch({
      type: FETCH_CUSTOMER_PENDING
    });
    try {
      const customer = await CustomerApi.useLocalToken().retrieve(customerId);
      dispatch({
        type: FETCH_CUSTOMER_SUCCESS,
        customer: customer
      });
    } catch (err) {
      // Notify sentry from here
      dispatch({
        type: FETCH_CUSTOMER_FAILED,
        error: err
      });
    }
  } else {
    dispatch({
      type: NO_CUSTOMER_STORED
    });
  }
};

export const updateCustomer = (customer, dispatch) => async changes => {
  const newCustomer = Object.assign(
    {
      customer_id: customer.customer_id,
      first_name: customer.first_name,
      last_name: customer.last_name
    },
    changes
  );

  dispatch({
    type: UPDATING_CUSTOMER_PENDING
  });

  // Send customer changes to api, non submitting will still apply the changes on prospect
  try {
    let customerResponse = await CustomerApi.useLocalToken().update(newCustomer, true);

    // Bps will get a change id, so do a submitting update on this
    if (customerResponse.change_id) {
      newCustomer.change_id = customerResponse.change_id;
      customerResponse = await CustomerApi.useLocalToken().update(newCustomer, false);
    }

    dispatch({
      type: UPDATING_CUSTOMER_SUCCESS,
      customer: customerResponse
    });
  } catch (err) {
    // Notify sentry from here
    dispatch({
      type: UPDATING_CUSTOMER_FAILED,
      error: err
    });
  }
};

export const clearStatuses = dispatch => () => {
  dispatch({
    type: CLEAR_STATUSES
  });
};
