import {
  CREATE_CUSTOMER_PENDING,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILED,
  UPDATING_CUSTOMER_PENDING,
  UPDATING_CUSTOMER_SUCCESS,
  UPDATING_CUSTOMER_FAILED,
  FETCH_CUSTOMER_PENDING,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAILED,
  NO_CUSTOMER_STORED,
  CLEAR_STATUSES
} from './Customer.action.js';

export default (oldState, action) => {
  const state = {
    ...oldState,
    customerCreateStatus: null,
    customerUpdateStatus: null,
    customerFetchStatus: null
  };
  switch (action.type) {
    case CLEAR_STATUSES:
      return state;
    case CREATE_CUSTOMER_PENDING:
      return {
        ...state,
        customerCreateStatus: 'pending',
        error: null
      };
    case CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customerCreateStatus: 'success',
        customer: action.customer
      };
    case CREATE_CUSTOMER_FAILED:
      return {
        ...state,
        customerCreateStatus: 'failed',
        error: action.error
      };
    case UPDATING_CUSTOMER_PENDING:
      return {
        ...state,
        customerUpdateStatus: 'pending',
        error: null
      };
    case UPDATING_CUSTOMER_SUCCESS:
      return {
        ...state,
        customerUpdateStatus: 'success',
        customer: action.customer
      };
    case UPDATING_CUSTOMER_FAILED:
      return {
        ...state,
        customerUpdateStatus: 'failed',
        error: action.error
      };
    case FETCH_CUSTOMER_PENDING:
      return {
        ...state,
        customerFetchStatus: 'pending',
        error: null
      };
    case FETCH_CUSTOMER_FAILED:
      return {
        ...state,
        customerFetchStatus: 'failed',
        error: action.error
      };
    case FETCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        customerFetchStatus: 'success',
        customer: action.customer
      };
    case NO_CUSTOMER_STORED:
      return {
        ...state,
        customerFetchStatus: 'non-stored',
        error: null
      };
    default:
      throw new Error(`Invalid Customer State: ${action.type}`);
  }
};
